import ShiboletTelavivB1F12 from "shared/assets/images/shibolet/tel-aviv-new/rubinstein/12.svg";
import ShiboletTelavivB1F13 from "shared/assets/images/shibolet/tel-aviv-new/rubinstein/13.svg";
import ShiboletTelavivB1F14 from "shared/assets/images/shibolet/tel-aviv-new/rubinstein/14.svg";
import ShiboletTelavivB1F15 from "shared/assets/images/shibolet/tel-aviv-new/rubinstein/15.svg";
import ShiboletTelavivB1F16 from "shared/assets/images/shibolet/tel-aviv-new/rubinstein/16.svg";
import ShiboletTelavivB1F17 from "shared/assets/images/shibolet/tel-aviv-new/rubinstein/17.svg";
import ShiboletTelavivB1F18 from "shared/assets/images/shibolet/tel-aviv-new/rubinstein/18.svg";

import ArnonTelavivSquareF33 from "shared/assets/images/arnon-tadmor-levy/tel-aviv/square/33.svg";
import ArnonTelavivSquareF34 from "shared/assets/images/arnon-tadmor-levy/tel-aviv/square/34.svg";
import ArnonTelavivSquareF37 from "shared/assets/images/arnon-tadmor-levy/tel-aviv/square/37.svg";
import ArnonTelavivSquareF38 from "shared/assets/images/arnon-tadmor-levy/tel-aviv/square/38.svg";
import ArnonTelavivSquareF39 from "shared/assets/images/arnon-tadmor-levy/tel-aviv/square/39.svg";
import ArnonTelavivSquareF40 from "shared/assets/images/arnon-tadmor-levy/tel-aviv/square/40.svg";
import { TBuildingPlan } from "shared/types/general";

type TCompanyMap = Record<string, Record<string, TBuildingPlan[]>>;

const SHIBOLET_FLOORS_MAPS: TCompanyMap = {
  ['tel-aviv-new']: {
    rubinstein: [
      { floor: 12, plan: ShiboletTelavivB1F12 },
      { floor: 13, plan: ShiboletTelavivB1F13 },
      { floor: 14, plan: ShiboletTelavivB1F14 },
      { floor: 15, plan: ShiboletTelavivB1F15 },
      { floor: 16, plan: ShiboletTelavivB1F16 },
      { floor: 17, plan: ShiboletTelavivB1F17 },
      { floor: 18, plan: ShiboletTelavivB1F18 }
    ]
  },
	['tel-aviv']: {
    rubinstein: [
      { floor: 12, plan: ShiboletTelavivB1F12 },
      { floor: 13, plan: ShiboletTelavivB1F13 },
      { floor: 14, plan: ShiboletTelavivB1F14 },
      { floor: 15, plan: ShiboletTelavivB1F15 },
      { floor: 16, plan: ShiboletTelavivB1F16 },
      { floor: 17, plan: ShiboletTelavivB1F17 },
      { floor: 18, plan: ShiboletTelavivB1F18 }
    ]
  }
};

const ARNON_FLOORS_MAPS: TCompanyMap = {
  ['tel-aviv']: {
    square: [
      { floor: 33, plan: ArnonTelavivSquareF33 },
      { floor: 34, plan: ArnonTelavivSquareF34 },
      { floor: 37, plan: ArnonTelavivSquareF37 },
      { floor: 38, plan: ArnonTelavivSquareF38 },
      { floor: 39, plan: ArnonTelavivSquareF39 },
      { floor: 40, plan: ArnonTelavivSquareF40 },
    ]
  }
};

const BUILDING_PLANS: Record<string, TCompanyMap> = {
  shibolet: SHIBOLET_FLOORS_MAPS,
	['arnon-tadmor-levy']: ARNON_FLOORS_MAPS
};

export const getBuildingPlan = (company: string, site: string, building: string) => {
  const companyName = normalizeNames(company);
  const siteName = normalizeNames(site);
  const buildingName = normalizeNames(building);

  if (!BUILDING_PLANS[companyName] || !BUILDING_PLANS[companyName][siteName] || !BUILDING_PLANS[companyName][siteName][buildingName]) {
    return [];
  }

  return BUILDING_PLANS[companyName][siteName][buildingName];
};

function normalizeNames(name: string) {
  return name.toLowerCase().replace(/[,.]/g, "").replace(/ /g, "-").trim();
}
