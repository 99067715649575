import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NotAssignedUsers from "../NotAssignedUsers/NotAssignedUsers";
import { useDispatch, useSelector } from "react-redux";
import { setShowNoAssignmentSidebar } from "features/workstations/workstationsSlice";
import {
  selectShowNoAssignmentSidebar,
  selectSiteFilter,
  selectBuildingFilter,
  selectAccessToken
} from "core/selectors";
import { useUnassignedEmployeesQuery } from "features/workstations/workstationApiSlice";
import { setUnassignedUsers } from "features/userManagement/userManagementSlice";
import { format } from "date-fns";

const dateToday = format(new Date(), "yyyy-MM-dd");
const mapStateToProps = (state) => ({
  showNoAssignmentSidebar: selectShowNoAssignmentSidebar(state),
  site: selectSiteFilter(state),
  building: selectBuildingFilter(state),
  token: selectAccessToken(state)
});

const UserWithoutWorkstationContainer = () => {
  /* ------------------ HOOKs ------------------ */

  const dispatch = useDispatch();

  /* ------------------ STATEs ------------------ */

  const [showToast, setShowToast] = useState(false);
  const { showNoAssignmentSidebar, site, building, token } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: usersWithoutWorkstation, isLoading: isUsersLoading } = useUnassignedEmployeesQuery(
    { site, date: dateToday, building },
    { skip: !site | !building || !token }
  );

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (usersWithoutWorkstation && !isUsersLoading) {
      if (usersWithoutWorkstation.employees.length) {
        setShowToast(true);
      }
      dispatch(setUnassignedUsers(usersWithoutWorkstation.employees));
    }
  }, [usersWithoutWorkstation, isUsersLoading]);

  useEffect(() => {
    if (showToast) {
      toast.error(ToastWithLink, { position: "top-center", autoClose: false, style: { width: "650px" } });
    }
  }, [showToast]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onToastClick = () => {
    dispatch(setShowNoAssignmentSidebar(true));
    setShowToast(false);
  };

  const setIsOpen = (value) => {
    dispatch(setShowNoAssignmentSidebar(value));
  };

  /* ------------------ RENDERs ------------------ */

  const ToastWithLink = () => {
    return (
      <div>
        <span>
          Some users arriving today do not have a workstation assigned. Click{" "}
          <span onClick={onToastClick} style={{ textDecoration: "underline" }}>
            here
          </span>{" "}
          to assign one.
        </span>
      </div>
    );
  };

  return <NotAssignedUsers isOpen={showNoAssignmentSidebar} setIsOpen={setIsOpen} />;
};

export default UserWithoutWorkstationContainer;
