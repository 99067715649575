// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  width: auto;
  height: auto;
}

ul {
  margin: 0;
}

.Toastify__toast {
  min-height: 45px;
}

/*.Toastify__toast-container {*/
/*  width: auto;*/
/*}*/

.Toastify__toast--success {
  border: 1px solid #08bc0c;
}

.Toastify__toast--error {
  border: 1px solid #e74c3c;
}

.Toastify__close-button {
  align-self: center;
}

.date-picker-full-width {
	width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA,+BAA+B;AAC/B,iBAAiB;AACjB,IAAI;;AAEJ;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;CACC,WAAW;AACZ","sourcesContent":["img {\n  width: auto;\n  height: auto;\n}\n\nul {\n  margin: 0;\n}\n\n.Toastify__toast {\n  min-height: 45px;\n}\n\n/*.Toastify__toast-container {*/\n/*  width: auto;*/\n/*}*/\n\n.Toastify__toast--success {\n  border: 1px solid #08bc0c;\n}\n\n.Toastify__toast--error {\n  border: 1px solid #e74c3c;\n}\n\n.Toastify__close-button {\n  align-self: center;\n}\n\n.date-picker-full-width {\n\twidth: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
