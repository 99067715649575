// @ts-nocheck

import { useRef, useState } from "react";
import { getCenter, getDistance } from "../helpers/mapHelpers";
import CustomTooltip from "../helpers/CustomTooltip";

export type TMainStageLogicProps = {
  stageScale?: number;
  xCoord?: number;
  yCoord?: number;
  containerWidth?: number;
};

export const TooltipClassName = "bwith-map-custom-tooltip";
export const mapInitialScale = 0.39364628371277405;

const MapTooltip = new CustomTooltip();
const tooltipEl = () => document.getElementsByClassName(TooltipClassName)[0];
const scaleBy = 1.01;

export default function useMainStageLogic({ stageScale = mapInitialScale, xCoord = 0, yCoord = 0, containerWidth }: TMainStageLogicProps) {
  const [stage, setStage] = useState({
    scale: stageScale,
    x: xCoord,
    y: yCoord
  });

  const width = containerWidth || window.innerWidth - 370;
  const height = window.innerHeight;

  const stageRef = useRef(null);
  let lastCenter = null;
  let lastDist = 0;

  const scaleRelativeToPoint = (point, increaseScale) => {
    const scaleBy = 1.06;
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: point.x / oldScale - stage.x() / oldScale,
      y: point.y / oldScale - stage.y() / oldScale
    };

    const newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy;
    MapTooltip.setMapScale(newScale);

    setStage({
      scale: newScale,
      x: (point.x / newScale - mousePointTo.x) * newScale,
      y: (point.y / newScale - mousePointTo.y) * newScale
    });
  };

  function zoomStage(event) {
    event.evt.preventDefault();
    MapTooltip.hide(tooltipEl());

    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale
      };
      const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      MapTooltip.setMapScale(newScale);

      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale
      };
      stage.position(newPos);
      stage.batchDraw();
    }
  }

  function handleTouch(e) {
    e.evt.preventDefault();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];
    const stage = stageRef.current;
    if (stage !== null) {
      if (touch1 && touch2) {
        if (stage.isDragging()) {
          stage.stopDrag();
        }

        const p1 = {
          x: touch1.clientX,
          y: touch1.clientY
        };
        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY
        };

        if (!lastCenter) {
          lastCenter = getCenter(p1, p2);
          return;
        }
        const newCenter = getCenter(p1, p2);

        const dist = getDistance(p1, p2);

        if (!lastDist) {
          lastDist = dist;
        }

        const pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX()
        };

        const scale = stage.scaleX() * (dist / lastDist);

        stage.scaleX(scale);
        stage.scaleY(scale);

        const dx = newCenter.x - lastCenter.x;
        const dy = newCenter.y - lastCenter.y;

        const newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy
        };

        stage.position(newPos);
        stage.batchDraw();

        lastDist = dist;
        lastCenter = newCenter;
      }
    }
  }

  function handleTouchEnd() {
    lastCenter = null;
    lastDist = 0;
  }

  function onMouseIn(e, workstation) {
    if (!workstation?.id || MapTooltip.getVisible()) return;

    MapTooltip.setVisible(true);
    MapTooltip.setDetails(workstation);
    MapTooltip.show(tooltipEl(), { x: e?.evt?.clientX, y: e?.evt?.clientY });
  }

  function onMouseOut() {
    if (!MapTooltip.getVisible()) return;

    MapTooltip.setVisible(false);
    MapTooltip.hide(tooltipEl());
  }

  function onWatchTooltip(e) {
    if (!MapTooltip.getVisible()) return;

    MapTooltip.move(tooltipEl(), { x: e.clientX, y: e.clientY });
  }

  return {
    width,
    height,
    zoomStage,
    handleTouch,
    handleTouchEnd,
    stage,
    stageRef,
    scaleRelativeToPoint,
    onMouseIn,
    onMouseOut,
    onWatchTooltip
  };
}
